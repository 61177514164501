import { View, StyleSheet, Pressable, Text } from "@/ui";
import { Colors } from "@/constants";
import Icon from "@/ui/Icon";
import { useUISlice } from "@/models/useUISlice";
import Menu from "@/ui/Menu";
import { getLangDir } from "@/models/utils";
import { useTranslation } from "react-i18next";
import DashboardTranscriptionListItem from "./DashboardTranscriptionListItem";
import { Transcription } from "@/api-lib";

const isRTL = getLangDir() === "rtl";

const DashboardTranscriptHeader = ({
  onDismissPress,
  onCopyPress,
  onExportPress,
  item,
}: {
  onDismissPress: () => void;
  onCopyPress: () => void;
  item: Transcription;
  onExportPress: () => void;
}) => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();

  return (
    <div style={{ ...s.stickyHeaderContainer, position: "sticky" }}>
      <View
        style={{
          ...s.stickyHeader,
          paddingHorizontal: isMobile ? 4 : undefined,
        }}
      >
        {!isMobile && (
          <View
            style={{ flexDirection: "row", alignItems: "center", height: 64 }}
          >
            <Pressable
              style={{ paddingHorizontal: 24 }}
              onPress={onDismissPress}
            >
              <Icon
                icon="caretDown"
                style={{
                  tintColor: Colors.text,
                  transform: [{ rotate: "90deg" }],
                }}
              />
            </Pressable>

            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{
                fontSize: 24,
                color: Colors.gray10,
                fontWeight: "600",
                maxWidth: 600,
                overflow: "hidden",
              }}
            >
              {DashboardTranscriptionListItem.formatName(item)}
            </Text>
          </View>
        )}
        {isMobile && (
          <Pressable
            style={{ ...s.headerIcon, marginLeft: -16 }}
            onPress={onDismissPress}
          >
            <Icon icon="close" style={{ tintColor: Colors.text }} />
          </Pressable>
        )}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Menu
            position={isRTL ? "left" : "right"}
            actions={[
              {
                text: t("dashboardTranscriptHeader.copy"),
                onPress: onCopyPress,
              },
              {
                text: t("dashboardTranscriptHeader.exportAs"),
                onPress: onExportPress,
              },
            ]}
            actionComponent={
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: Colors.accent,
                  paddingHorizontal: 16,
                  height: 38,
                  borderRadius: 12,
                  backgroundColor: Colors.accent,
                }}
              >
                <Icon
                  icon="export"
                  style={{ tintColor: Colors.white, width: 18, height: 18 }}
                />
                <Text
                  style={{ color: Colors.white, fontSize: 16, marginLeft: 8 }}
                >
                  {t("dashboardTranscriptHeader.export")}
                </Text>
              </View>
            }
          />
        </View>
      </View>
    </div>
  );
};

const s = StyleSheet.create({
  stickyHeaderContainer: {
    backgroundColor: Colors.white,
    paddingVertical: 48,
    height: 64,
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  fileName: {
    fontSize: 24,
    color: Colors.gray10,
    fontWeight: "600",
  },
  stickyHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerIcon: {
    width: 64,
    height: 64,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 48,
  },
});
export default DashboardTranscriptHeader;
