export * from "./Colors";
export * from "../../mobile/_shared/transcriptionOptions";

export const PRICE_MONTHLY = 19.99;
export const PRICE_YEARLY = 199;

export const TRIAL_DURATION_IN_SECONDS = 6 * 60; // 6 minutes

export const AVAILABLE_LOCALES = ["ar", "en", "es", "it", "pt"];

export const DESKTOP_WIDTH = 1079;

export const SUPPORTED_FILE_EXTENSIONS = [
  ".3ga",
  ".webm",
  ".8svx",
  ".mts",
  ".m2ts",
  ".ts",
  ".aac",
  ".mov",
  ".ac3",
  ".mp2",
  ".aif",
  ".mp4",
  ".m4v",
  ".aiff",
  ".mxf",
  ".alac",
  ".amr",
  ".ape",
  ".au",
  ".dss",
  ".flac",
  ".flv",
  ".m4a",
  ".m4b",
  ".m4p",
  ".m4r",
  ".mp3",
  ".mpga",
  ".ogg",
  ".oga",
  ".mogg",
  ".opus",
  ".qcp",
  ".tta",
  ".voc",
  ".wav",
  ".wma",
  ".wv",
];

export const __DEV__ =
  Boolean(process) && process.env.NODE_ENV === "development";
