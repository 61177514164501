import { Text, StyleSheet, View, Pressable } from "@/ui";
import { useUserSlice } from "@/models/useUserSlice";
import * as serverClient from "@/models/serverClient";
import { datadogLogs } from "@datadog/browser-logs";
import { Colors } from "@/constants";
import { useUISlice } from "@/models/useUISlice";
import { useTranslation } from "react-i18next";
import Router, { useRouter } from "next/router";
import Icon from "./Icon";
import TextLink from "./TextLink";
import { PurchasePlan, UserAccess } from "@/api-lib";
import { useEffect } from "react";
import { getLocalizedBasePath } from "@/models/utils";

const Paywall = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const paywallVisible = useUISlice((state) => state.alertWidgetVisible);

  useEffect(() => {
    const slug = router.query.checkout_success;
    if (slug === "true") {
      Paywall.getUserAccess(9999);

      useUISlice.getState().setAlertModal({
        title: t("paywall.subscriptionSucceededTitle"),
        shouldPersistBackdropClick: true,
        subtitle: t("paywall.subscriptionSucceededSubtitle"),
        button: {
          text: t("paywall.okButton"),
          onPress: () => {
            useUISlice.getState().setAlertModal(undefined);
            router.replace(`${getLocalizedBasePath()}/dashboard`, undefined, {
              shallow: true,
            });
          },
        },
      });
    }
  }, [router.query]);

  const getPlan = (): PurchasePlan => {
    const { user } = useUserSlice.getState();
    const country = (user && user.country) || "US";

    let nextPlan: PurchasePlan = "MONTHLY_LOW_TIER";

    switch (country) {
      case "US":
      case "CA":
      case "UK":
      case "AU":
        nextPlan = "MONTHLY_TOP_TIER";
        break;
    }

    return nextPlan;
  };

  const getPrice = () => {
    const currentPlan = getPlan();

    const MAPPER: { [key in PurchasePlan]: string } = {
      MONTHLY_LOW_TIER: "$9.99",
      MONTHLY_MID_TIER: "$14.99",
      MONTHLY_TOP_TIER: "$19.99",
      PROMO_ONE_DOLLAR: "$1",
    };

    const price = MAPPER["MONTHLY_TOP_TIER"];

    const discountPrice = MAPPER[currentPlan];

    return {
      price,
      discountPrice,
      hasDiscount: price !== discountPrice,
    };
  };

  const { price, discountPrice, hasDiscount } = getPrice();

  if (paywallVisible !== "paywall") return null;

  return (
    <View style={{ position: "fixed", ...s.modal } as any}>
      <View style={s.container}>
        <View style={s.header}>
          <View />

          <Pressable
            onPress={() =>
              useUISlice.getState().setAlertWidgetVisible(undefined)
            }
          >
            <Icon icon="close" />
          </Pressable>
        </View>

        <Text style={s.title}>{t("paywall.title")}</Text>
        <Text style={s.subtitle}>{t("paywall.subtitle")}</Text>

        <View style={{ marginTop: 16 }}>
          <View style={s.featureItem}>
            <Icon icon="tick" style={s.featureItemIcon} />
            <Text style={s.featureItemText}>{t("paywall.feature1")}</Text>
          </View>

          <View style={{ height: 8 }} />
          <View style={s.featureItem}>
            <Icon icon="tick" style={s.featureItemIcon} />
            <Text style={s.featureItemText}>{t("paywall.feature2")}</Text>
          </View>

          <View style={{ height: 8 }} />
          <View style={s.featureItem}>
            <Icon icon="tick" style={s.featureItemIcon} />
            <Text style={s.featureItemText}>{t("paywall.feature3")}</Text>
          </View>
        </View>

        <View style={{ height: 32 }} />

        <TextLink
          text={t("paywall.askQuestion")}
          style={{
            alignSelf: "center",
            textAlign: "center",
            backgroundColor: "pink",
            paddingHorizontal: 6,
            color: Colors.text,
            fontWeight: "600",
            lineHeight: 26,
          }}
          onPress={() => {
            useUISlice.getState().setAlertWidgetVisible(undefined);

            useUISlice.getState().setAlertPrompt({
              title: t("paywall.askQuestionTitle"),
              subtitle: t("paywall.askQuestionSubtitle"),
              placeholder: t("paywall.askQuestionPlaceholder"),
              multiLine: true,
              button: {
                text: t("paywall.askQuestionButton"),
                onPress: async (text) => {
                  await serverClient
                    .sendInquiryAsync({
                      inquiry: text.trim(),
                    })
                    .then((res) => {
                      useUISlice.getState().setAlertPrompt(undefined);
                      router.push(
                        `https://wa.me/16132033779?text=${encodeURIComponent(
                          `${text}\n\n--- ${t("paywall.doNotRemove")} ---\n${t("paywall.email")}: ${useUserSlice.getState().user.email}`
                        )}`
                      );
                    });
                },
              },
            });
          }}
        />

        {hasDiscount && (
          <View style={s.saleEndsSoon}>
            <Text style={s.saleEndsSoonText}>
              {t("paywall.saleEndsSoon").toUpperCase()}
            </Text>
          </View>
        )}

        <Pressable
          onPress={() => Paywall.gotoCheckout(getPlan())}
          style={s.button}
        >
          <Text style={s.price}>
            {hasDiscount && <Text style={s.priceLineThrough}>{price}</Text>}
            {t("paywall.price", {
              price: discountPrice,
            })}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

Paywall.gotoCheckout = async (plan: PurchasePlan) => {
  const response = await serverClient.getPaymentLinkAsync({
    baseAppUrl: window.location.href,
    purchasePlan: plan,
  });

  if (response.data) {
    datadogLogs.logger.info("Funnel 9: Checkout", {
      deviceId: useUserSlice.getState().deviceId,
      purchasePlan: plan,
      userAccess: useUserSlice.getState().userAccess,
    });

    Router.push(response.data.url);
  }
};

Paywall.getUserAccess = async (
  durationInSeconds: number
): Promise<UserAccess> => {
  const userAccessResponse = await serverClient.getUserAccessAsync({
    durationInSeconds,
  });

  if (userAccessResponse && userAccessResponse.data) {
    useUserSlice.getState().setUserAccess(userAccessResponse.data);

    return userAccessResponse.data;
  }

  return undefined;
};

const s = StyleSheet.create({
  modal: {
    zIndex: 100,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.65)",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    width: "100%",
    maxWidth: 360,
    borderRadius: 2,
    backgroundColor: Colors.white,
  },
  header: {
    paddingBottom: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fddb6b",
    borderWidth: 1,
    borderColor: "#f2c94d",
    borderRadius: 80,
    paddingHorizontal: 15,
    height: 30,
  },
  headerLeftIcon: {
    tintColor: "#8c5303",
    width: 14,
    height: 14,
    resizeMode: "contain",
  },
  headerLeftText: {
    color: "#8c5303",
    marginLeft: 4,
    fontWeight: "bold",
    fontSize: 12,
  },
  title: {
    fontSize: 32,
    color: Colors.text,
    fontWeight: "700",
    marginBottom: 8,
  },
  subtitle: {
    fontSize: 16,
    color: Colors.subtitle,
    lineHeight: 24,
  },
  featureItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  featureItemIcon: {
    width: 16,
    height: 16,
    tintColor: Colors.text,
  },
  featureItemText: {
    color: Colors.text,
    fontSize: 16,
    marginLeft: 6,
  },
  button: {
    marginTop: 32,
    height: 64,
    backgroundColor: Colors.accent,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  saleEndsSoon: {
    position: "absolute",
    top: -16,
    left: 12,
    borderRadius: 32,
    backgroundColor: "#fddb6b",
    borderWidth: 1,
    borderColor: "#f2c94d",
    paddingHorizontal: 16,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  saleEndsSoonText: {
    fontSize: 12,
    textAlign: "center",
    color: "#8c5303",
    marginLeft: 4,
    fontWeight: "bold",
  },
  price: {
    fontSize: 18,
    fontWeight: "600",
    color: Colors.white,
  },
  priceLineThrough: {
    textDecorationLine: "line-through",
    color: Colors.gray4,
    marginRight: 6,
    fontWeight: "300",
  },
});
export default Paywall;
