import { getLocale } from "@/models/utils";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: { translation: require("./en.json") },
};

if (!i18n.isInitialized && typeof window === "object") {
  let deviceLang = getLocale();

  i18n.use(initReactI18next).init({
    resources,
    lng: deviceLang,
    fallbackLng: "en",
    compatibilityJSON: "v3",
    interpolation: {
      escapeValue: false,
    },
  });
}

export { i18n };
