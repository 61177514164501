import { useEffect, useState } from "react";
import { Pressable, View, Text, StyleSheet, ActivityIndicator } from "@/ui";
import Icon from "@/ui/Icon";
import { Colors } from "@/constants";
import { Transcription } from "@/api-lib";
import { useRouter } from "next/router";
import { useUISlice } from "@/models/useUISlice";
import { useUserSlice } from "@/models/useUserSlice";
import Menu from "@/ui/Menu";
import * as serverClient from "@/models/serverClient";
import { datadogLogs } from "@datadog/browser-logs";
import { getLangDir, getLocalizedBasePath } from "@/models/utils";
import { useTranslation } from "react-i18next";
import AlertDashboardViewTranscript from "./AlertDashboardViewTranscript";
import Paywall from "@/ui/Paywall";

const isRTL = getLangDir() === "rtl";

const DashboardTranscriptionListItem = ({ item }: { item: Transcription }) => {
  const isMobile = useUISlice((state) => state.isMobile);
  const transcriptionId = AlertDashboardViewTranscript.getTranscriptionId();
  const isSelected = useUISlice(
    (state) =>
      state.selectedTranscriptions[item.id] || item.id === transcriptionId
  );
  const { t } = useTranslation();

  const router = useRouter();

  const [isHovered, setIsHovered] = useState(false);
  const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);

  useEffect(() => {
    const startPolling = () => {
      if (item.status !== "transcription_started") return;

      const existingPollingFn =
        useUISlice.getState().pollingTranscriptionFn[item.id];

      if (existingPollingFn) return;

      const pollingFn = () => {
        const intervalId = setInterval(async () => {
          const response = await serverClient.getTranscriptionAsync({
            transcriptionId: item.id,
          });

          if (
            response &&
            response.data &&
            response.data.transcription &&
            response.data.transcription.status !== "transcription_started"
          ) {
            datadogLogs.logger.info("Funnel 5: Transcription completed", {
              deviceId: response.data?.transcription.deviceId,
              status: response.data?.transcription.status,
              transcriptionId: response.data?.transcription.id,
              durationInSeconds: response.data?.transcription.durationInSeconds,
            });

            // If status has changed, update the item and stop polling
            useUserSlice
              .getState()
              .setTranscriptions(
                useUserSlice
                  .getState()
                  .transcriptions.map((t) =>
                    t.id === item.id ? response.data?.transcription : t
                  )
              );
            clearInterval(intervalId);
            // Remove the polling function from the state
            const updatedPollingFns = {
              ...useUISlice.getState().pollingTranscriptionFn,
            };
            delete updatedPollingFns[item.id];
            useUISlice.getState().setPollingTranscriptionFn(updatedPollingFns);
          }
        }, 3000); // Poll every second

        // Store the interval ID so we can clear it later if needed
        return () => clearInterval(intervalId);
      };

      // Store the polling function in the state
      useUISlice.getState().setPollingTranscriptionFn({
        ...useUISlice.getState().pollingTranscriptionFn,
        [item.id]: pollingFn,
      });

      // Start polling immediately
      pollingFn();
    };

    startPolling();
  }, [item.status]);

  const _onPress = async () => {
    const updatedItem = useUserSlice
      .getState()
      .transcriptions.find((t) => t.id === item.id);

    if (updatedItem?.status === "upload_completed") {
      const userAccess = await Paywall.getUserAccess(
        updatedItem.durationInSeconds
      );

      if (!userAccess) return;

      const { canTranscribe, oneDollarPromoEnabled } = userAccess;

      if (!canTranscribe) {
        if (oneDollarPromoEnabled) {
          return useUISlice.getState().setAlertModal({
            title: t("paywall.newUserPromoTitle"),
            subtitle: t("paywall.newUserPromoSubtitle"),
            cancelText: t("paywall.cancelButton"),
            button: {
              text: t("paywall.newUserPromoPrice"),
              onPress: () => {
                Paywall.gotoCheckout("PROMO_ONE_DOLLAR");
                useUISlice.getState().setAlertModal(undefined);
              },
            },
          });
        }

        useUISlice.getState().setAlertModal(undefined);
        return useUISlice.getState().setAlertWidgetVisible("paywall");
      }

      return useUISlice.getState().setAlertStartTranscription(updatedItem);
    }

    if (updatedItem?.status === "transcription_started") {
      return useUISlice.getState().setAlertModal({
        title: t("dashboardTranscriptionListItem.almostThereTitle"),
        subtitle: t("dashboardTranscriptionListItem.almostThereSubtitle"),
        button: {
          text: t("dashboardTranscriptionListItem.okButton"),
          onPress: () => useUISlice.getState().setAlertModal(undefined),
        },
      });
    }

    if (updatedItem?.status === "transcription_failed") {
      return useUISlice.getState().setAlertModal({
        title: t("dashboardTranscriptionListItem.failedTitle"),
        subtitle: t("dashboardTranscriptionListItem.failedSubtitle"),
        button: {
          text: t("dashboardTranscriptionListItem.okButton"),
          onPress: () => useUISlice.getState().setAlertModal(undefined),
        },
      });
    }

    useUISlice.getState().setSelectedTranscriptions({});

    router.push(
      `${getLocalizedBasePath()}/dashboard?transcriptionId=${item.id}`
    );
  };

  const _onRenameFile = () => {
    const updatedItem = useUserSlice
      .getState()
      .transcriptions.find((t) => t.id === item.id);

    useUISlice.getState().setAlertPrompt({
      title: t("dashboardTranscriptionListItem.renameTitle"),
      subtitle: t("dashboardTranscriptionListItem.renameSubtitle"),
      placeholder: t("dashboardTranscriptionListItem.renamePlaceholder"),
      defaultValue: updatedItem?.fileName.replace(
        `.${updatedItem.fileExtension}`,
        ""
      ),
      button: {
        text: t("dashboardTranscriptionListItem.renameButton"),
        onPress: async (text) => {
          useUISlice.getState().setAlertPrompt({
            ...useUISlice.getState().alertPrompt,
            loading: true,
          });

          const res = await serverClient.updateTranscriptionAsync({
            type: "OnNameChange",
            updates: {
              deviceId: updatedItem?.deviceId,
              fileId: updatedItem?.fileId,
              fileName: `${text}.${updatedItem?.fileExtension}`,
            },
          });

          datadogLogs.logger.info("Funnel 6.1: Transcription renamed", {
            deviceId: updatedItem?.deviceId,
            transcriptionId: updatedItem?.id,
          });
          useUISlice.getState().setAlertPrompt(undefined);

          if (res.data && res.data.transcription) {
            useUserSlice
              .getState()
              .setTranscriptions(
                useUserSlice
                  .getState()
                  .transcriptions.map((t) =>
                    t.id === item.id ? res.data.transcription : t
                  )
              );
          }
        },
      },
    });
  };

  const _onDeleteFile = () => {
    const updatedItem = useUserSlice
      .getState()
      .transcriptions.find((t) => t.id === item.id);

    if (updatedItem?.status === "transcription_started") return;

    useUISlice.getState().setAlertModal({
      title: t("dashboardTranscriptionListItem.deleteTitle"),
      subtitle: t("dashboardTranscriptionListItem.deleteSubtitle", {
        fileName: updatedItem?.fileName.replace(
          `.${updatedItem.fileExtension}`,
          ""
        ),
      }),
      cancelText: t("dashboardTranscriptionListItem.cancelButton"),
      button: {
        text: t("dashboardTranscriptionListItem.deleteButton"),
        destructive: true,
        onPress: async () => {
          useUISlice.getState().setAlertModal({
            ...useUISlice.getState().alertModal,
            loading: true,
          });

          const res = await serverClient.deleteTranscriptionAsync({
            transcriptionId: item.id,
          });

          useUISlice.getState().setAlertModal(undefined);
          datadogLogs.logger.info("Funnel 6.2: Transcription deleted", {
            deviceId: updatedItem?.deviceId,
            transcriptionId: updatedItem?.id,
          });

          if (res && !res.error) {
            useUserSlice
              .getState()
              .setTranscriptions(
                useUserSlice
                  .getState()
                  .transcriptions.filter((t) => t.id !== item.id)
              );
          }
        },
      },
    });
  };

  const _onCheckboxPress = () => {
    if (isSelected) {
      useUISlice.getState().setSelectedTranscriptions({
        ...useUISlice.getState().selectedTranscriptions,
        [item.id]: undefined,
      });
    } else {
      useUISlice.getState().setSelectedTranscriptions({
        ...useUISlice.getState().selectedTranscriptions,
        [item.id]: item,
      });
    }
  };

  return (
    <Pressable
      onPress={_onPress}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      style={StyleSheet.flatten([
        styles.tableRow,
        isHovered && { backgroundColor: Colors.gray1 },
        isSelected && { backgroundColor: Colors.gray1 },
      ])}
    >
      <Pressable
        onPress={_onCheckboxPress}
        onHoverIn={() => setIsCheckboxHovered(true)}
        onHoverOut={() => setIsCheckboxHovered(false)}
        style={{
          backgroundColor: isCheckboxHovered ? Colors.gray1 : "transparent",
          borderRadius: 24,
          width: 56,
          height: 56,
          alignItems: "center",
          justifyContent: "center",
          marginRight: 16,
          marginLeft: -16,
        }}
      >
        {!isSelected && <View style={styles.checkbox} />}
        {isSelected && (
          <View
            style={StyleSheet.flatten([
              styles.checkbox,
              {
                backgroundColor: Colors.accent,
                borderColor: Colors.accent,
                alignItems: "center",
                justifyContent: "center",
              },
            ])}
          >
            <Icon
              icon="tick"
              style={{
                tintColor: Colors.white,
                width: 12,
                height: 12,
              }}
            />
          </View>
        )}
      </Pressable>

      <View
        style={{
          flex: 3,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 48,
        }}
      >
        <Text
          numberOfLines={1}
          style={[
            styles.cellText,
            styles.nameCell,
            isMobile && { fontSize: 16 },
            isHovered && { textDecorationLine: "underline" },
          ]}
        >
          {DashboardTranscriptionListItem.formatName(item)}
        </Text>

        {!isMobile && (
          <Menu
            position={isRTL ? "left" : "right"}
            actions={[
              {
                text: t("dashboardTranscriptionListItem.viewTranscript"),
                onPress: _onPress,
              },
              {
                text: t("dashboardTranscriptionListItem.renameFile"),
                onPress: _onRenameFile,
              },
              {
                text: t("dashboardTranscriptionListItem.deleteFile"),
                destructive: true,
                onPress: _onDeleteFile,
              },
            ]}
            actionComponent={
              <View style={styles.moreCell}>
                <Icon icon="more" style={styles.moreIcon} />
              </View>
            }
          />
        )}
      </View>

      <View
        style={StyleSheet.flatten([
          styles.statusCell,
          isMobile && { alignItems: "center" },
        ])}
      >
        {item.status === "upload_completed" && (
          <Icon
            icon="incomplete"
            style={{ ...styles.statusIcon, tintColor: Colors.subtitle }}
          />
        )}
        {item.status === "transcription_completed" && (
          <Icon icon="check" style={styles.statusIcon} />
        )}
        {item.status === "transcription_failed" && (
          <Icon
            icon="close"
            style={{ ...styles.statusIcon, tintColor: Colors.error }}
          />
        )}
        {item.status === "transcription_started" && (
          <ActivityIndicator size="small" color={Colors.accent} />
        )}
      </View>

      {isMobile && (
        <Menu
          position={isRTL ? "left" : "right"}
          actions={[
            {
              text: t("dashboardTranscriptionListItem.viewTranscript"),
              onPress: _onPress,
            },
            {
              text: t("dashboardTranscriptionListItem.renameFile"),
              onPress: _onRenameFile,
            },
            {
              text: t("dashboardTranscriptionListItem.deleteFile"),
              destructive: true,
              onPress: _onDeleteFile,
            },
          ]}
          actionComponent={
            <View
              style={StyleSheet.flatten([
                styles.moreCell,
                { transform: [{ rotate: "90deg" }] },
              ])}
            >
              <Icon icon="more" style={styles.moreIcon} />
            </View>
          }
        />
      )}

      {!isMobile && (
        <Text style={[styles.cellText, styles.durationCell]}>
          {DashboardTranscriptionListItem.formatDuration(
            item.durationInSeconds
          )}
        </Text>
      )}

      {!isMobile && (
        <Text style={[styles.cellText, styles.uploadedCell]}>
          {DashboardTranscriptionListItem.formatUploaded(item.createdAt)}
        </Text>
      )}
    </Pressable>
  );
};

DashboardTranscriptionListItem.formatName = (item: Transcription) => {
  return item.fileName.replace(`.${item.fileExtension}`, "");
};

DashboardTranscriptionListItem.formatDuration = (durationInSeconds: number) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};

DashboardTranscriptionListItem.formatUploaded = (createdAt: string) => {
  return new Date(createdAt).toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: Colors.gray3,
    height: 56,
    alignItems: "center",
    paddingHorizontal: 16,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1.5,
    borderColor: Colors.subtitle,
    borderRadius: 2,
  },
  cellText: {
    color: Colors.text,
    fontSize: 14,
  },
  nameCell: {
    textAlign: isRTL ? "right" : "left",
    flex: 3,
    fontSize: 18,
    color: Colors.text,
    fontWeight: "400",
    textDecorationColor: Colors.text,
    textDecorationStyle: "solid",
  },
  uploadedCell: {
    flex: 2,
    textAlign: isRTL ? "right" : "left",
  },
  durationCell: {
    flex: 1,
    textAlign: isRTL ? "right" : "left",
  },
  statusCell: {
    flex: 1,
    alignItems: "flex-start",
  },
  statusIcon: {
    tintColor: Colors.success,
  },
  moreCell: {
    alignItems: "center",
    width: 56,
    height: 56,
    borderRadius: 24,
    justifyContent: "center",
  },
  moreIcon: {
    transform: [{ rotate: "90deg" }],
    tintColor: Colors.text,
    width: 16,
    height: 16,
  },
});

export default DashboardTranscriptionListItem;
