import { View, StyleSheet, Text, Pressable, ActivityIndicator } from "@/ui";
import Icon from "@/ui/Icon";
import { Colors } from "@/constants";
import PlayerProgress from "@/ui/PlayerProgress";
import { useUISlice } from "@/models/useUISlice";
import { getLangDir } from "@/models/utils";

const isRTL = getLangDir() === "rtl";

const AudioPlayer = ({
  duration,
  _pauseOrPlay,
  _seekPrevious,
  _seekNext,
  _setRate,
  _seekPosition,
  isPlaying,
  rate,
  position,
  loading,
}: {
  duration: number;
  _pauseOrPlay: () => void;
  _seekPrevious: () => void;
  _seekNext: () => void;
  _setRate: () => void;
  _seekPosition: (position: number) => void;
  isPlaying: boolean;
  rate: number;
  position: number;
  loading: boolean;
}) => {
  const isMobile = useUISlice((state) => state.isMobile);

  return (
    <View style={{ direction: "ltr" }}>
      <View style={{ ...s.container }}>
        <View style={s.buttonContainer}>
          <Pressable onPress={_pauseOrPlay} disabled={loading} style={s.button}>
            {loading && (
              <ActivityIndicator color={Colors.accent} size={"small"} />
            )}

            {!loading && (
              <Icon
                disabled={loading}
                icon={isPlaying ? "pause" : "play"}
                style={{ width: 16, height: 16, tintColor: Colors.accent }}
              />
            )}
          </Pressable>

          <View style={s.divider} />

          {!isMobile && (
            <Pressable
              onPress={_seekPrevious}
              disabled={loading}
              style={s.button}
            >
              <Icon
                icon="timeBackward"
                style={{ width: 20, height: 20, tintColor: Colors.accent }}
              />
            </Pressable>
          )}

          {!isMobile && <View style={s.divider} />}

          {!isMobile && (
            <Pressable onPress={_seekNext} disabled={loading} style={s.button}>
              <Icon
                icon="timeForward"
                style={{ width: 20, height: 20, tintColor: Colors.accent }}
              />
            </Pressable>
          )}

          {!isMobile && <View style={s.divider} />}

          {!isMobile && (
            <Pressable onPress={_setRate} disabled={loading} style={s.button}>
              <Text style={s.rateText}>{rate}X</Text>
            </Pressable>
          )}
        </View>

        <View style={{ flexGrow: 1, flex: isMobile ? 1 : undefined }}>
          <PlayerProgress
            onPositionChange={_seekPosition}
            position={position}
            duration={duration}
          />
        </View>
      </View>
    </View>
  );
};

AudioPlayer.HEIGHT = 64;

const s = StyleSheet.create({
  container: {
    width: "100%",
    height: AudioPlayer.HEIGHT,
    borderTopWidth: 1,
    borderColor: Colors.gray3,
    backgroundColor: Colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    height: AudioPlayer.HEIGHT,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    paddingLeft: 12,
  },
  button: {
    width: 48,
    height: 48,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 48,
  },
  divider: {
    marginHorizontal: 12,
    width: 1,
    height: 24,
    backgroundColor: Colors.gray3,
  },
  rateText: {
    fontWeight: "500",
    color: Colors.accent,
    fontSize: 16,
  },
});

export default AudioPlayer;
