import { View, Pressable, Text, StyleSheet } from "@/ui";
import { Colors } from "@/constants";
import { Transcript, Transcription } from "@/api-lib";
import { useState } from "react";
import { useUISlice } from "@/models/useUISlice";
import * as serverClient from "@/models/serverClient";
import { useUserSlice } from "@/models/useUserSlice";
import { useTranslation } from "react-i18next";
import { i18n } from "@/locales";

const DashboardTranscriptItem = ({
  transcript,
  currentIndex,
  isSelected,
  onTranscriptItemPress,
  transcriptionId,
}: {
  transcript: Transcript;
  currentIndex: number;
  isSelected: boolean;
  transcriptionId: string;
  onTranscriptItemPress: (positionInSeconds: number) => void;
}) => {
  const { t } = useTranslation();
  const transcription = useUserSlice((state) => state.transcriptions).find(
    (t) => t.id === transcriptionId
  );
  const [isHovered, setIsHovered] = useState(false);

  const currentItem = transcript[currentIndex];
  const previousItem = transcript[currentIndex - 1];

  const transcriptEdits = useUserSlice(
    (state) => state.transcriptsEdits[transcription.id] || {}
  );

  if (transcriptEdits[currentItem.start]) {
    currentItem.text = transcriptEdits[currentItem.start];
  }

  let hideSpeaker = !currentItem.speaker;
  if (previousItem && previousItem.speaker === currentItem.speaker) {
    hideSpeaker = true;
  }

  const editButtonVisible = isHovered;

  const _formatDuration = (durationInSeconds: number) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else if (minutes > 0) {
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return `0:${seconds.toString().padStart(2, "0")}`;
    }
  };

  const _onEditPress = () => {
    useUISlice.getState().setAlertPrompt({
      title: t("dashboardTranscriptCenterItem.editSectionTitle"),
      subtitle: t("dashboardTranscriptCenterItem.editSectionSubtitle", {
        start: _formatDuration(currentItem.start),
        end: _formatDuration(currentItem.end),
      }),
      placeholder: t("dashboardTranscriptCenterItem.editSectionPlaceholder"),
      multiLine: true,
      defaultValue: currentItem.text?.trim() || "",
      button: {
        text: t("dashboardTranscriptCenterItem.saveChanges"),
        onPress: async (text) => {
          useUISlice.getState().setAlertPrompt({
            ...useUISlice.getState().alertPrompt,
            loading: true,
          });

          const result = await serverClient.editTranscriptAsync({
            transcriptionId: transcription.id,
            start: currentItem.start,
            text: text.trim(),
          });

          if (result && result.data) {
            useUserSlice.getState().setTranscriptsEdits({
              [transcription.id]: result.data.transcriptEdit.transcript,
            });
          }

          useUISlice.getState().setAlertPrompt(undefined);
        },
      },
    });
  };

  const _onEditSpeakerPress = (defaultText: string) => {
    useUISlice.getState().setAlertPrompt({
      title: t("dashboardTranscriptCenterItem.renameSpeakerTitle", {
        speaker: DashboardTranscriptItem.formatSpeaker(
          currentItem.speaker,
          transcription
        ),
      }),
      subtitle: t("dashboardTranscriptCenterItem.renameSpeakerSubtitle"),
      placeholder: t("dashboardTranscriptCenterItem.renameSpeakerPlaceholder"),
      defaultValue: defaultText,
      button: {
        text: t("dashboardTranscriptCenterItem.saveChanges"),
        onPress: async (text) => {
          const index = Number(currentItem.speaker.split("_")[1]);

          useUISlice.getState().setAlertPrompt({
            ...useUISlice.getState().alertPrompt,
            loading: true,
          });

          const response = await serverClient.updateTranscriptionAsync({
            type: "OnSpeakersMappersChange",
            updates: {
              deviceId: transcription.deviceId,
              fileId: transcription.fileId,
              speakersMappers: {
                ...(transcription.speakersMappers || {}),
                [index]: text,
              },
            },
          });

          if (response.data && response.data.transcription) {
            useUserSlice
              .getState()
              .setTranscriptions(
                useUserSlice
                  .getState()
                  .transcriptions.map((t) =>
                    t.id === transcription.id ? response.data.transcription : t
                  )
              );
          }

          useUISlice.getState().setAlertPrompt(undefined);
        },
      },
    });
  };

  return (
    <View>
      {!hideSpeaker && (
        <Pressable
          onPress={() =>
            _onEditSpeakerPress(
              DashboardTranscriptItem.formatSpeaker(
                currentItem.speaker,
                transcription
              )
            )
          }
        >
          <Text style={styles.speaker}>
            {DashboardTranscriptItem.formatSpeaker(
              currentItem.speaker,
              transcription
            )}
          </Text>
        </Pressable>
      )}

      <Pressable
        onPress={() => onTranscriptItemPress(currentItem.start)}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        style={{
          marginHorizontal: -12,
          paddingHorizontal: 12,
          marginBottom: 4,
          backgroundColor:
            isHovered || isSelected ? Colors.hover : "transparent",
        }}
      >
        <Text style={styles.paragraph}>
          <Text
            style={{ color: Colors.gray6, fontSize: 14, fontWeight: "300" }}
          >
            ({currentIndex === 0 ? "0:00" : _formatDuration(currentItem.start)}){" "}
          </Text>
          {currentItem.text.trim()}
        </Text>

        {editButtonVisible && (
          <Pressable
            onPress={_onEditPress}
            onHoverIn={() => setIsHovered(true)}
            style={{
              position: "absolute",
              top: -20,
              right: 24,
              backgroundColor: Colors.accent,
              paddingHorizontal: 12,
              paddingVertical: 6,
              borderRadius: 2,
            }}
          >
            <Text style={{ color: Colors.white }}>
              {t("dashboardTranscriptCenterItem.editSection")}
            </Text>
          </Pressable>
        )}
      </Pressable>
    </View>
  );
};

DashboardTranscriptItem.formatSpeaker = (
  speaker: string,
  transcription: Transcription
) => {
  const index = Number(speaker.split("_")[1]);

  if (transcription.speakersMappers && index in transcription.speakersMappers) {
    return transcription.speakersMappers[index];
  }

  return i18n.t(`dashboardTranscriptCenterItem.speaker`, {
    index: index + 1,
  });
};

const styles = StyleSheet.create({
  speaker: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 16,
    textDecorationLine: "underline",
    textDecorationColor: Colors.accent,
    textDecorationStyle: "dotted",
    marginBottom: 4,
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 26,
    marginTop: 8,
    marginBottom: 8,
    color: Colors.text,
  },
});

export default DashboardTranscriptItem;
