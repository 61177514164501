import { useEffect, useRef, useState } from "react";
import { ActivityIndicator, ScrollView, StyleSheet, View } from "@/ui";
import { useUserSlice } from "@/models/useUserSlice";
import toast from "react-hot-toast";
import * as serverClient from "@/models/serverClient";
import {
  Transcript,
  TranscriptEdit,
  TranscriptExportDocument,
  Transcription,
} from "@/api-lib";
import AudioPlayer from "@/ui/AudioPlayer";
import { Colors } from "@/constants";
import { useAudioPlayer } from "react-use-audio-player";
import { useUISlice } from "@/models/useUISlice";
import {
  convertToSubtitleTime,
  exportTranscript,
  exportTranscriptToDocx,
  exportTranscriptToPdf,
  formatDuration,
  getLocalizedBasePath,
} from "@/models/utils";
import { datadogLogs } from "@datadog/browser-logs";
import DashboardTranscriptTitle from "@/ui/DashboardTranscriptTitle";
import DashboardTranscriptItem from "@/ui/DashboardTranscriptItem";
import DashboardTranscriptHeader from "@/ui/DashboardTranscriptHeader";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

const AlertDashboardViewTranscript = () => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();
  const router = useRouter();
  const player = useAudioPlayer();
  const frameRef = useRef<number>();

  const transcriptionId = AlertDashboardViewTranscript.getTranscriptionId();
  const [transcription, setTranscription] = useState<Transcription>();
  const transcript = useUserSlice(
    (state) => state.transcripts[transcriptionId]
  );
  const [rate, setRate] = useState(1);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const fetchTranscription = async () => {
      const userToken = useUserSlice.getState().userToken;
      const transcriptionId = AlertDashboardViewTranscript.getTranscriptionId();

      if (!userToken || !transcriptionId) return;

      const userTranscription = useUserSlice
        .getState()
        .transcriptions.find(
          (transcription) => transcription.id === transcriptionId
        );

      if (userTranscription) {
        setTranscription(userTranscription);
        AlertDashboardViewTranscript.getTranscriptWithEdits(userTranscription);
        player.load(userTranscription.fileUrl, {
          autoplay: false,
          html5: true,
        });
      }

      const transcriptionResponse = await serverClient.getTranscriptionAsync({
        transcriptionId,
      });

      if (!transcriptionResponse || transcriptionResponse.error) return;

      setTranscription(transcriptionResponse.data?.transcription);

      if (!userTranscription) {
        AlertDashboardViewTranscript.getTranscriptWithEdits(userTranscription);
        player.load(transcriptionResponse.data?.transcription.fileUrl, {
          autoplay: false,
          html5: true,
        });
        useUserSlice
          .getState()
          .setTranscriptions([
            ...useUserSlice.getState().transcriptions,
            transcriptionResponse.data?.transcription,
          ]);
      }

      datadogLogs.logger.info("User transcription found", {
        transcriptionId,
      });
    };

    fetchTranscription();
  }, [transcriptionId]);

  useEffect(() => {
    const animate = () => {
      setPosition(player.getPosition());
      frameRef.current = requestAnimationFrame(animate);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [player.getPosition]);

  const _setRate = async () => {
    let nextRate = 1;

    switch (player.rate) {
      case 1:
        nextRate = 1.25;
        break;

      case 1.25:
        nextRate = 1.5;
        break;

      case 1.5:
        nextRate = 1.75;
        break;

      case 1.75:
        nextRate = 2;
        break;
      case 2:
        nextRate = 0.25;
        break;
      case 0.25:
        nextRate = 0.5;
        break;
      case 0.5:
        nextRate = 0.75;
        break;

      case 0.75:
        nextRate = 1;
        break;

      default:
        nextRate = 1;
    }

    player.setRate(nextRate);
    setRate(nextRate);
  };

  const _copyText = async () => {
    const transcription = useUserSlice
      .getState()
      .transcriptions.find(
        (transcription) =>
          transcription.id === AlertDashboardViewTranscript.getTranscriptionId()
      );

    if (!transcription) return;

    const { transcriptWithEdits } =
      await AlertDashboardViewTranscript.getTranscriptWithEdits(transcription);

    await navigator.clipboard.writeText(
      transcriptWithEdits
        .map((t) => t.text.trim())
        .join("\n\n")
        .trim()
    );

    toast.success(t("dashboardTranscriptRight.copyText"));

    datadogLogs.logger.info("Funnel 8: Transcript copied", {
      deviceId: transcription.deviceId,
      transcriptionId: transcription.id,
    });
  };

  const _downloadAudio = async () => {
    const transcriptionId = AlertDashboardViewTranscript.getTranscriptionId();
    const transcription = useUserSlice
      .getState()
      .transcriptions.find((t) => t.id === transcriptionId);

    if (!transcription) return;

    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    downloadLink.href = transcription.fileUrl;
    downloadLink.download = transcription?.fileName;

    // Append to the document body and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);

    datadogLogs.logger.info("Funnel 8: Audio downloaded", {
      deviceId: transcription.deviceId,
      transcriptionId: transcription.id,
    });
  };

  const _exportTranscript = async ({
    withSpeakers = false,
    withTimestamps = false,
    withMonologue = true,
    exportType = "txt",
  }: {
    withSpeakers: boolean;
    withTimestamps: boolean;
    withMonologue: boolean;
    exportType: TranscriptExportDocument;
  }) => {
    const transcription = useUserSlice
      .getState()
      .transcriptions.find(
        (transcription) =>
          transcription.id === AlertDashboardViewTranscript.getTranscriptionId()
      );

    if (!transcription) return;

    const { transcriptWithEdits } =
      await AlertDashboardViewTranscript.getTranscriptWithEdits(transcription);

    const { fileName, output } =
      AlertDashboardViewTranscript.getTranscriptExportOutput({
        exportType,
        transcription,
        transcriptWithEdits,
        withMonologue,
        withSpeakers,
        withTimestamps,
      });

    switch (exportType) {
      case "txt":
      case "srt":
        await exportTranscript({
          fileName,
          extension: exportType,
          items: output,
        });
        break;

      case "docx":
        await exportTranscriptToDocx({
          fileName,
          items: output,
        });
        break;

      case "pdf":
        await exportTranscriptToPdf({
          fileName,
          items: output,
        });
        break;
    }

    datadogLogs.logger.info("Funnel 8: Transcript exported", {
      deviceId: transcription.deviceId,
      transcriptionId: transcription.id,
    });
  };

  const _onDismiss = () => {
    router.replace(`${getLocalizedBasePath()}/dashboard`);
    setTranscription(undefined);
    player.cleanup();
  };

  let data = transcript || [];

  if (!transcriptionId) return null;

  return (
    <>
      <div
        style={{
          position: "fixed",
          ...(!isMobile ? s.modal : {}),
          boxShadow: isMobile ? undefined : "-4px 0 15px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
          maxHeight: "100vh",
          width: "100%",
          backgroundColor: Colors.white,
        }}
      >
        {transcription && (
          <View
            style={{
              ...s.container,
              width: "100%",
              height: "100%",
              paddingBottom: 48,
              paddingHorizontal: isMobile ? 4 : undefined,
            }}
          >
            <DashboardTranscriptHeader
              item={transcription}
              onDismissPress={_onDismiss}
              onCopyPress={_copyText}
              onExportPress={() => {
                useUISlice.getState().setTranscriptOptions({
                  hasSpeakers: false,
                  onExport: (options) => {
                    _exportTranscript(options);
                  },
                });
              }}
            />

            <ScrollView
              style={{
                ...s.contentContainer,
                paddingHorizontal: isMobile ? 8 : undefined,
                marginTop: isMobile ? 8 : undefined,
              }}
            >
              <DashboardTranscriptTitle
                itemId={transcription.id}
                onCopyPress={_copyText}
                onExportTranscriptPress={_exportTranscript}
                onDownloadAudioPress={_downloadAudio}
              />

              {!transcript && (
                <View style={{ padding: 32 }}>
                  <ActivityIndicator size="small" color={Colors.accent} />
                </View>
              )}

              {transcript &&
                data.map((segment, index) => {
                  const start = segment.start || 0;
                  const end = segment.end || transcription.durationInSeconds;

                  return (
                    <DashboardTranscriptItem
                      transcriptionId={transcription.id}
                      isSelected={position > start && position < end}
                      onTranscriptItemPress={(seconds) => {
                        if (player.isLoading) return;

                        player.seek(seconds);
                        player.play();
                      }}
                      key={segment.start}
                      transcript={transcript}
                      currentIndex={index}
                    />
                  );
                })}
            </ScrollView>

            <div
              style={{
                flexGrow: 1,
                width: "100%",
                maxWidth: 940,
                position: "fixed",
                bottom: 0,
                right: isMobile ? 8 : 16,
                backgroundColor: Colors.white,
              }}
            >
              <AudioPlayer
                duration={transcription.durationInSeconds}
                loading={player.isLoading}
                _pauseOrPlay={player.togglePlayPause}
                _seekPrevious={() => player.seek(player.getPosition() - 10)}
                _seekNext={() => player.seek(player.getPosition() + 10)}
                _setRate={_setRate}
                _seekPosition={player.seek}
                isPlaying={player.playing}
                rate={rate}
                position={player.getPosition()}
              />
            </div>
          </View>
        )}
      </div>
    </>
  );
};

AlertDashboardViewTranscript.getTranscriptionId = () => {
  return (window.location.search.split("transcriptionId=")[1] || "").toString();
};

AlertDashboardViewTranscript.getTranscriptWithEdits = async (
  item: Transcription
): Promise<{
  transcript: Transcript;
  transcriptWithEdits: Transcript;
}> => {
  if (!item || !item.transcriptUrl) {
    return { transcript: [], transcriptWithEdits: [] };
  }

  let transcript = useUserSlice.getState().transcripts[item.id];

  if (!transcript) {
    const transcriptString = (await fetch(item.transcriptUrl).then((res) =>
      res.json()
    )) as string;

    transcript = JSON.parse(transcriptString) as Transcript;

    useUserSlice.getState().setTranscripts({ [item.id]: transcript });
  }

  const transcriptEditsResponse = await serverClient.getTranscriptEditAsync({
    transcriptionId: item.id,
  });

  let transcriptEdit: TranscriptEdit;

  if (transcriptEditsResponse && transcriptEditsResponse.data) {
    transcriptEdit = transcriptEditsResponse.data.transcriptEdit;

    useUserSlice.getState().setTranscriptsEdits({
      [item.id]: transcriptEditsResponse.data.transcriptEdit.transcript,
    });
  }

  if (!transcriptEdit) {
    return { transcript, transcriptWithEdits: transcript };
  }

  const transcriptWithEdits = transcript.map((item) => {
    const edit = transcriptEdit.transcript[item.start.toString()];
    if (!edit) return item;

    return { ...item, text: edit };
  });

  return { transcript, transcriptWithEdits };
};

AlertDashboardViewTranscript.getTranscriptExportOutput = ({
  withSpeakers,
  withTimestamps,
  withMonologue,
  exportType,
  transcription,
  transcriptWithEdits,
}: {
  withSpeakers: boolean;
  withTimestamps: boolean;
  withMonologue: boolean;
  transcriptWithEdits: Transcript;
  exportType: TranscriptExportDocument;
  transcription: Transcription;
}): { fileName: string; output: { text: string; metadata?: string }[] } => {
  let output: { metadata?: string; text: string }[] = [];

  if (withSpeakers && withTimestamps) {
    output = transcriptWithEdits.map((t) => {
      const speakerName = DashboardTranscriptItem.formatSpeaker(
        t.speaker,
        transcription
      );

      const start = formatDuration(t.start * 1000);
      const end = formatDuration(t.end * 1000);

      let metadata = `${speakerName} • ${start} - ${end}`;

      return { text: t.text, metadata };
    });
  }

  if (withSpeakers && !withTimestamps) {
    output = transcriptWithEdits.map((t) => {
      const speakerName = DashboardTranscriptItem.formatSpeaker(
        t.speaker,
        transcription
      );

      let metadata = `${speakerName}`;

      return { text: t.text, metadata };
    });
  }

  if (!withSpeakers && withTimestamps) {
    output = transcriptWithEdits.map((t) => {
      let start = formatDuration(t.start * 1000);
      let end = formatDuration(t.end * 1000);

      let metadata = `${start} - ${end}`;

      if (exportType === "srt") {
        start = convertToSubtitleTime(t.start * 1000);
        end = convertToSubtitleTime(t.end * 1000);
        metadata = `${start} --> ${end}`;
      }

      return { text: t.text, metadata };
    });
  }

  if (!withSpeakers && !withTimestamps) {
    output = transcriptWithEdits.map((t) => ({ text: t.text }));
  }

  if (withMonologue) {
    output = [{ text: transcriptWithEdits.map((t) => t.text).join(" ") }];
  }

  const fileName = transcription.fileName.replace(
    `.${transcription.fileExtension}`,
    ""
  );

  return { fileName, output };
};

const s = StyleSheet.create({
  modal: {
    zIndex: 10,
    top: 0,
    right: 0,
    maxWidth: 940,
    width: "100%",
    height: "100%",
    backgroundColor: Colors.white,
    padding: 16,
    paddingTop: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
  },
  contentContainer: {
    flexGrow: 1,
    padding: 24,
    paddingTop: 0,
    width: "100%",
    backgroundColor: Colors.white,
  },
});

export default AlertDashboardViewTranscript;
