import { useState, useMemo } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import Uppy, { UppyFile } from "@uppy/core";
import { View } from "@/ui";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import { useUserSlice } from "@/models/useUserSlice";
import { audioLanguages } from "@/constants";
import AwsS3, { type AwsS3UploadParameters } from "@uppy/aws-s3";
import PickerSelectLanguage from "@/ui/PickerSelectLanguage";
import PrimaryButton from "@/ui/PrimaryButton";
import * as serverClient from "@/models/serverClient";
import { useTranslation } from "react-i18next";
import { Transcription } from "@/api-lib";
import { useRouter } from "next/router";
import { getLocalizedBasePath } from "@/models/utils";

async function getFileDuration(file: File): Promise<number> {
  if ((file as any).duration) return (file as any).duration;

  const video = document.createElement("video");
  video.src = URL.createObjectURL(file);
  video.preload = "metadata";
  video.muted = true;
  video.autoplay = false;
  video.playsInline = true;

  return new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      resolve(video.duration);
    });
  });
}

async function getUploadParameters(file: UppyFile<any, any>) {
  const duration = await getFileDuration(file.data as File);

  const response = await serverClient.createTranscriptionAsync({
    durationInSeconds: Number(duration.toFixed(2)),
    fileId: file.id,
    isRecording: Boolean((file.data as any).duration),
    fileName: file.name.trim(),
    fileSizeMb: Number((file.size / 1024 / 1024).toFixed(2)),
    fileExtension: file.name.split(".").pop(),
  });

  if (response.error) {
    datadogLogs.logger.error("Failed to create transcription", {
      error: response.error,
    });
    throw new Error("Failed to create transcription");
  }

  const object: AwsS3UploadParameters = {
    method: "PUT",
    url: response.data.uploadUrl,
    fields: {},
    headers: {
      "Content-Type": file.type ? file.type : "application/octet-stream",
    },
  };

  return object;
}

const AlertStartTranscription = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();

  const currentFile = useUISlice((state) => state.alertStartTranscription);

  const transcriptionOptions = useUserSlice(
    (state) => state.transcriptionOptions
  );
  const currentLanguageIndex = audioLanguages.findIndex(
    (language) => language.value === transcriptionOptions.languageCode
  );

  const uppy = useMemo(() => {
    const allowedFreeMaxFileSizeMb = 1 * 1024 * 1024 * 1024;

    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: allowedFreeMaxFileSizeMb,
      },
      onBeforeFileAdded: (file) => {
        return { ...file, id: new Date().getTime().toString() };
      },
    }).use(AwsS3, {
      id: "AwsS3",
      shouldUseMultipart: false,
      getUploadParameters: (file) => getUploadParameters(file),
    });
    return uppy;
  }, []);

  uppy.on("complete", async (result) => {
    if (!result.successful[0]) return;

    const fileId = result.successful[0].id;

    const processed = localStorage.getItem("processed");
    if (processed === fileId) return;

    localStorage.setItem("processed", fileId);

    const updateResponse = await serverClient.updateTranscriptionAsync({
      type: "OnStatusChange",
      updates: {
        fileId,
        deviceId: useUserSlice.getState().user.deviceId,
        status: "upload_completed",
      },
    });

    if (
      updateResponse &&
      updateResponse.data &&
      updateResponse.data.transcription
    ) {
      datadogLogs.logger.info("Funnel 3: Upload completed", {
        deviceId: updateResponse.data.transcription.deviceId,
        language: updateResponse.data?.transcription.languageCode,
        fileSize: updateResponse.data?.transcription.fileSizeMb,
        duration: updateResponse.data?.transcription.durationInSeconds,
      });
    }

    _onUploadCompleted(updateResponse.data.transcription);
  });

  uppy.on("upload-progress", (file, progress) => {
    setUploadProgress(
      Math.round((progress.bytesUploaded / progress.bytesTotal) * 100)
    );
  });

  uppy.on("error", (error) => {
    datadogLogs.logger.error("UploadError: upload failed", {
      deviceId: useUserSlice.getState().deviceId,
      error,
    });
  });

  const _onUploadCompleted = async (transcription: Transcription) => {
    setIsLoading(true);
    const updatedTranscriptionOptions =
      useUserSlice.getState().transcriptionOptions;

    const responseOrError = await serverClient.startTranscriptionAsync({
      fileId: transcription.fileId,
      languageCode: updatedTranscriptionOptions.languageCode,
    });

    setIsLoading(false);

    if (responseOrError && responseOrError.error) {
      return datadogLogs.logger.error(
        "Error starting transcription",
        responseOrError
      );
    }

    datadogLogs.logger.info("Funnel 4: Transcription started", {
      deviceId: transcription.deviceId,
      duration: transcription.durationInSeconds,
      languageCode: updatedTranscriptionOptions.languageCode,
    });

    useUserSlice.getState().setTranscriptions(responseOrError.data.items);
    _onDismiss();

    router.push(`${getLocalizedBasePath()}/note/${transcription.id}`);
  };

  const _onPressTranscribe = () => {
    if (currentFile instanceof File) {
      uppy.addFile(currentFile);
      return setUploadProgress(1);
    }
  };

  const _onDismiss = () => {
    setUploadProgress(0);
    useUISlice.getState().setAlertStartTranscription(undefined);
    uppy.removeFiles(uppy.getFiles().map((file) => file.id));
  };

  if (!currentFile) return null;

  const isDisabled = uploadProgress > 0 || isLoading;
  const fileName =
    currentFile instanceof File ? currentFile.name : currentFile.fileName;

  return (
    <ModalContainer
      shouldPersistBackdropClick
      title={fileName}
      onDismiss={_onDismiss}
      hideCancel
      subtitle={t("alertStartTranscription.subtitle", { fileName })}
      modalMaxWidth={360}
      modalWidth={360}
    >
      <View style={{ width: "100%" }}>
        <View style={{ zIndex: 0, width: "100%", marginTop: 16 }}>
          <PickerSelectLanguage
            disabled={isDisabled}
            onChange={(languageCode) => {
              useUserSlice.getState().setTranscriptionOptions({
                ...transcriptionOptions,
                languageCode,
              });
            }}
            label={t("alertStartTranscription.spokenLanguage")}
            defaultCurrentIndex={currentLanguageIndex}
          />
        </View>

        <View style={{ marginTop: 16 }} />

        <PrimaryButton
          height={48}
          textStyle={{ fontSize: 18 }}
          disabled={isDisabled}
          text={
            uploadProgress > 0
              ? t("alertStartTranscription.uploading", {
                  progress: uploadProgress,
                })
              : t("alertStartTranscription.startTranscription")
          }
          loading={isLoading}
          onPress={_onPressTranscribe}
        />
        <View style={{ marginBottom: -32 }} />
      </View>
    </ModalContainer>
  );
};

export default AlertStartTranscription;
