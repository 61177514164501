import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import React from "react";
import { StyleSheet, Text, View } from "@/ui";
import { Colors } from "@/constants";

export function formatDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

const PlayerProgress = ({
  duration,
  position,
  onPositionChange,
}: {
  duration: number;
  position: number;
  onPositionChange: (nextPosition: number) => void;
}) => {
  return (
    <View style={s.sliderContainer}>
      <Slider
        styles={{
          track: { backgroundColor: `${Colors.accent}90` },
          handle: {
            borderColor: Colors.accent,
            backgroundColor: Colors.accent,
          },
        }}
        min={0}
        max={duration}
        value={position}
        onChange={(value) => onPositionChange(value as number)}
      />

      <View style={s.durationTextContainer}>
        <Text style={s.durationText}>
          {formatDuration(Math.abs(position))} /{" "}
        </Text>
        <Text style={s.durationText}>{formatDuration(duration)}</Text>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  sliderContainer: {
    width: "100%",
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  durationTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 12,
  },
  durationText: { fontSize: 12, color: Colors.text },
});

export default PlayerProgress;
